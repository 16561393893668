<template>
  <div class="page-unread">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        <template v-if="index === 0"> {{ num }} ({{ unread }}) </template>
        <template v-if="index === 1"> {{ num }} ({{ read }}) </template>
      </div>
    </div>
    <reading v-if="tabNum === 0" :id="id" />
    <readed v-if="tabNum === 1" :id="id" />
  </div>
</template>

<script>
import Reading from './Reading/index'
import Readed from './Readed/index'
export default {
  components: {
    Reading,
    Readed
  },
  data () {
    return {
      tabs: ['未读', '已读'],
      unread: 0,
      read: 0,
      tabNum: 0,
      id: null
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.unread = this.$route.query.unread || 0
    this.read = this.$route.query.read || 0
  },
  methods: {
    tabClick (index) {
      this.tabNum = index
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
